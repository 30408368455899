@import ../../../../../styles/helpers

.row
    display: table-row
    +t
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:not(:last-child)
        .col
            border-bottom: 1px solid $n3
            +t
                border: none
            +dark
                border-color: $n6
    &:hover
        cursor: pointer
        +rmin(768)
            background: $n2
            +dark
                background: $n6
    
.col
    display: table-cell
    vertical-align: top
    padding-top: 20px
    padding-bottom: 20px
    +t
        display: flex
        align-items: center
        padding: 0
    &:not(:first-child)
        padding-top: 20px
        +t
            padding-top: 0
    &:not(:last-child)
        padding-right: 24px
        +t
            margin-bottom: 12px
            padding-top: 0

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: stretch
    +dark
        color: $n1
    &:hover
        color: $p1

.preview
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 20px
    border-radius: 8px
    overflow: hidden
    +t
        width: 104px
    img
        width: 100%
        height: 100%
        object-fit: cover

.product
    max-width: 170px
    margin-bottom: 4px
    +t
        max-width: 100%
        margin-bottom: 8px
        padding-right: 42px
        +title2
.wrap
    +t
        display: flex
        align-items: center

.category
    +caption1
    color: $n4
    +t
        font-size: 15px
    +dark
        color: $shades1

.sales
    display: inline-flex
    align-items: center

.number,
.price
    display: inline-block
    padding: 0 8px
    border-radius: 6px
    background: $n3
    transition: background .2s
    +dark
        background: $n5
        color: $n1

.price
    display: none
    +t
        display: inline-block
        margin-right: 12px

.balance
    margin-left: 8px

.box
    display: flex
    align-items: center

.line
    position: relative
    flex-shrink: 0
    width: 44px
    height: 8px
    margin-left: 8px
    +d
        width: 36px
    +t
        width: 44px

.progress
    position: absolute
    top: 0
    left: 0
    bottom: 0
    border-radius: 2px

.status
    transition: background .2s

.control
    position: absolute
    left: 12px
    bottom: 16px
    visibility: hidden
    opacity: 0
    transition: all .2s
    +t
        top: 0
        left: auto
        right: 0
        bottom: auto
        visibility: visible
        opacity: 1

.label
    display: none
    +t
        display: block
        flex-shrink: 0
        width: 124px
        padding-right: 20px
        +caption1
        color: $n4
