@import ../../../styles/helpers

.main
    position: relative

.container
    display: flex
    align-items: center
    min-height: 900px
    padding-top: 112px
    padding-bottom: 112px
    +d
        padding-top: 80px
    +m
        display: block
        min-height: auto
        padding-top: 32px
        padding-bottom: 16px

.wrap
    position: relative
    z-index: 3
    max-width: 545px
    +t
        max-width: 350px
    +m
        max-width: 100%
        margin-bottom: 16px
    
.title
    margin-bottom: 20px
    
.bg
    position: absolute
    right: calc(50% - 775px)
    width: 785px
    pointer-events: none
    +d
        right: calc(50% - 670px)
        width: 700px
    +m
        position: static
        width: 100%
        padding-left: 16px
    img
        width: 100%