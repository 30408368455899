@import ../../../styles/helpers

.description
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

.group
    display: flex
    flex-wrap: wrap
    align-items: flex-end
    margin: 0 -6px -12px
    +m
        display: block
        margin: 0
    .field
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
            
.customField
    display: inline-block
    padding: 10px
    width: 50%

.form
    margin-bottom: 10px

.formInput 
    margin-right: 10px
    padding: 5px

.formButton 
    padding: 5px 10px
    background-color: #007bff
    color: #fff
    border: none
    cursor: pointer
    &:hover
        background-color: #0056b3

.desc
    margin-top: 10px

.textArea
    width: 100%
    border-radius: 10px
    resize: none
    backgroundColor: #F4F4F4
    border: 1px solid #57555eba
    padding: 5px
    color: #1A1D1F
    overflow: hidden