@import ../../styles/helpers

.head
    display: flex
    justify-content: space-between
    margin-bottom: 12px
    padding-bottom: 24px
    border-bottom: 1px solid $n3
    +caption1
    color: $n4
    +dark
        border-color: $n6

.list
    margin: 0 -12px

.item
    display: flex
    align-items: center
    padding: 8px
    color: $n7
    cursor: pointer
    +dark
        color: $n1
    &:hover
        .title
            color: $p1
    &:not(:last-child)
        margin-bottom: 12px

.preview
    flex-shrink: 0
    width: 32px
    height: 32px
    margin-right: 12px
    border-radius: 8px
    overflow: hidden
    img
        width: 100%
        height: 100%
        object-fit: cover

.title
    flex-grow: 1
    padding-right: 16px
    transition: color .2s

.details
    flex-shrink: 0
    text-align: right

.button
    width: 100%
    margin-top: 12px
