@import ../../../styles/helpers

.row
    display: table-row
    color: $n8
    +t
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
            color: $n
    &:not(:last-child)
        .col
            border-bottom: 1px solid $n3
            +t
                border: none
            +dark
                border-color: $n6
                color: $n
    
.col
    display: table-cell
    padding-top: 12px
    padding-bottom: 2px
    +t
        display: flex
        align-items: center
        padding: 0
    &:not(:first-child)
        padding-top: 12px
        +t
            padding-top: 0
    &:not(:last-child)
        padding-right: 24px
        +t
            margin-bottom: 12px
            padding-top: 0