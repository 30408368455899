@import ../../styles/helpers

.modal
  display: flex
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 48px
  background: rgba($n2,.8)
  opacity: 0
  animation: showModal .4s forwards
  overflow: auto
  z-index: 999
  +m
    padding: 16px
  +dark
    background: rgba($n6, .9)

@keyframes showModal
  0%
    opacity: 0
  100%
    opacity: 1