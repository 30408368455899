@import ../../../styles/helpers

.nav
    display: flex
    margin-bottom: 32px
    padding: 8px
    border-radius: 20px
    flex-wrap: wrap
    gap: 10px 
    justify-content: space-between
    width: 100%
    +m
        margin-bottom: 24px
    +dark
        background: $shades4

.item
    &.active
        background: $n1
        +dark
            background: $n6
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)
            
.overview
    display: flex
    flex-direction: column
    width: 100%