@import ../../../styles/helpers

.head
    +m
        display: block

.title
    +m
        margin: 0 0 16px

.nav
    display: flex
    margin-left: auto
    .button
        padding: 8px 16px
        border-radius: 8px
        +base1-s
        color: $n4
        transition: all .2s
        +m
            flex: 1
        &:hover,
        &.active
            color: $n7
            +dark
                color: $n1
        &.active
            background: $n3
            +dark
                background: $n6
        &:not(:last-child)
            margin-right: 4px
            +m
                margin-right: 0

.wrapper
    margin: 0 -12px
    +m
        margin: 0

.table
    display: table
    width: 100%
    +m
        display: block

.row
    display: table-row
    +m
        display: flex
        margin-bottom: 24px

.col
    display: table-cell
    padding: 4px 12px 16px
    +caption2
    font-weight: 500
    color: $n4
    +m
        padding: 0
    &:first-child
        +m
            margin-right: auto
    &:nth-child(2)
        +m
            display: none
    &:nth-child(3)
        +t
            display: none

.foot
    margin-top: 12px
    text-align: center
    +m
        margin-top: 32px
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px
.btns
    margin-top: 64px
    text-align: center
    +m
        margin-top: 48px

.customImage
    width : 300px

.customButton
    margin: 15px 0px
    text-align: center
    +m
        margin-top: 48px
.button
    margin-right: 5px

.products
    margin: 10px 0px 