@import ../../styles/helpers

.answer
    display: none
    position: relative
    margin-top: 28px
    padding-left: 60px
    +m
        padding-left: 52px
    &:before
        content: ""
        position: absolute
        top: -23px
        left: -45px
        width: 33px
        height: 44px
        border-bottom-left-radius: 12px
        border-width: 0 0 2px 2px
        border-style: solid
        border-color: $n3
        +m
            left: -37px
        +dark
            border-color: $n5
    &.active
        display: block

.avatar
    position: absolute
    top: 0
    left: 0
    width: 40px
    height: 40px
    & > img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.details
    flex-grow: 1
    textarea
        display: block
        overflow: hidden
        width: 100%
        max-width: 360px
        min-height: 19px
        padding: 0
        resize: none
        background: none
        border: none
        font-size: 15px
        font-weight: 600
        color: $n6
        +m
            padding-right: 8px
        +dark
            color: $n1
        &::placeholder
            color: $shades4
            +dark
                color: $shades2

.btns
    display: flex
    margin-top: 16px
    .button
        &:nth-child(2)
            +dark
                background: $n7
                box-shadow: inset 0 0 0 2px $n7
                &:hover
                    background: $n8
                    box-shadow: inset 0 0 0 2px $n8
        &:not(:last-child)
            margin-right: 8px