@import ../../../styles/helpers

.list
    display: flex
    justify-content: space-between
    +m
        display: block

.item
    display: block
    padding: 0 32px 0 64px
    color: $n7
    +x
        padding: 0 32px 0 32px
    +t
        padding: 0
    +m
        display: flex
        flex-direction: row-reverse
        width: 100%
    +dark
        color: $n1
    &:not(:first-child)
        border-width: 0 0 0 1px
        border-style: solid
        border-color: $n3
        +m
            margin-top: 24px
            padding-top: 24px
            border-width: 1px 0 0
        +dark
            border-color: $n6
    &:first-child
        padding-left: 0
    &:last-child
        padding-right: 0
    &:not(:last-child)

.icon
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    margin-bottom: 24px
    border-radius: 50%
    +m
        margin: 0 0 0 auto
    svg
        fill: $n7

.label
    display: flex
    align-items: center
    margin-bottom: 8px
    +caption1
    color: $n4

.tooltip
    svg
        fill: $n4

.counter
    min-width: 146px
    margin-bottom: 8px
    font-size: 48px
    font-weight: 600
    line-height: 1
    letter-spacing: -.03em
    +t
        min-width: auto
        font-size: 40px
    +m
        font-size: 48px

.indicator
    display: inline-flex
    align-items: center
    padding: 4px
    color: $n4
    +dark
        background: $n6
    span
        margin-left: 4px
        +caption2

.dropdown
    margin-right: 3px
    width: 170px

.datePickerContainer
    display: flex
    flex-direction: column

.datePickerLabel 
    margin-bottom: 5px
    font-weight: bold

.customDatePicker 
    width: 200px
    margin-right: 10px
    padding: 8px
    border: 1px solid #ced4da
    box-shadow: inset 0 0 0 2px $n3
    font-size: 16px
    outline: none
    position: relative
    height: 48px
    padding: 0 48px 0 12px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n3
    font-size: 14px
    font-weight: 600
    line-height: 48px
    color: $n7
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    user-select: none
    transition: all .2s
    &:before
        content: ""
        position: absolute
        top: 0
        right: 0
        bottom: 0
        width: 48px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
        transition: transform .2s
    &:hover
        box-shadow: inset 0 0 0 2px $shades1
    +dark
        box-shadow: inset 0 0 0 2px $n6
        background: none
        color: $n
        &:hover
            box-shadow: inset 0 0 0 2px $n5