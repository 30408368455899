@import ../../../styles/helpers

.description
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

.group
    display: flex
    flex-wrap: wrap
    align-items: flex-end
    margin: 0 -6px -12px
    +m
        display: block
        margin: 0
    .field
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 
        +m
            width: 100%
            margin: 12px 0 0

.button
    margin-top: 20px

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    +t
        display: none

.col
    display: table-cell
    padding-top: 16px
    padding-bottom: 16px
    border-bottom: 1px solid $n3
    +caption2
    font-weight: 500
    color: $n4
    +dark
        border-color: $n6
    &:not(:last-child)
        padding-right: 24px

