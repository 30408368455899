@import ../../../styles/helpers

.description
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

.group
    display: flex
    flex-wrap: wrap
    align-items: flex-end
    margin: 0 -6px -12px
    +m
        display: block
        margin: 0
    .field
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0

.customField
    display: inline-block
    width: 100%
    margin: 10px 0

.errorMessage
    color: red

.radiobuttonscontainer
  display: flex
  gap: 10px
  margin-bottom: 5px

label
  display: flex
  align-items: center
  font-size: 14px
  cursor: pointer
  margin-bottom: 2px

label input[type='radio'] 
  margin-right: 10px

.selectedoptioncontainer
  margin-top: 10px
  background-color: #fff

.selectedoptioncontainer p
  font-size: 12px
  color: #333

.datePickerContainer
  margin-bottom: 20px

.datePickerLabel
  font-size: 14px
  color: #555
  margin-bottom: 8px
  display: block

.customDatePicker
  width: 100%
  padding: 10px
  font-size: 14px
  color: #333
  border: 1px solid #ccc
  border-radius: 4px
  background-color: #f9f9f9
  box-sizing: border-box

  &:focus
    outline: none
    border-color: #007bff
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5)

  &::placeholder
    color: #aaa
    
textarea
  min-height: 100px
  resize: vertical

.userselectionform h2
    +dark
        color: #fff