@import ../../../styles/helpers

.wrapper
    margin: 0 -12px
    +t
        margin: 0

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    +t
        display: none
    &.active
        .col
            &:not(:first-child):not(:nth-child(2))
                display: none
            &:nth-child(2)
                &:after
                    right: 12px

.col
    position: relative
    display: table-cell
    padding: 0 12px 16px
    vertical-align: middle
    +caption1
    color: $n4
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
    &:first-child
        width: 56px
        font-size: 0
        &:after
            left: 12px
    &:last-child
        &:after
            right: 12px
    
.foot
    margin-top: 20px
    text-align: center
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px

.customField
    display: inline-block
    padding: 10px
    width: 50%
    border: 3px solid $n3

.fieldset
    margin-bottom: 20px
    border-radius: 12px
    border: 5px solid $n3

.title
    text-align: center

.itemClass
    margin-top: 20px

.tableTitle
    padding-left: 10px
    +title1-m
    
    