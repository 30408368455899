@import ../../../styles/helpers

.description
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

.group
    display: flex
    flex-wrap: wrap
    align-items: flex-end
    margin: 0 -6px -12px
    +m
        display: block
        margin: 0
    .field
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0

.customField
    display: inline-block
    padding: 10px
    width: 50%

.form
    margin-bottom: 10px

.formInput 
    margin-right: 10px
    padding: 5px

.formButton 
    padding: 5px 10px
    background-color: #007bff
    color: #fff
    border: none
    cursor: pointer
    &:hover
        background-color: #0056b3

.formControl
    display: flex, 
    marginTop: 15px,
    flexDirection: column

.form
    display: flex
    flexDirection: row
    alignItems: flex-end
    flexWrap: wrap

.nameField
    flex: 1
    marginRight: 10px
    marginBottom: 10px
    minWidth: 300px

.styledbutton
    margin-top: 10px
    padding: 5px 
    font-size: 16px
    font-weight: bold
    text-align: center
    text-decoration: none
    display: inline-block
    cursor: pointer
    border-radius: 5px
    border: 2px solid #22767d
    color: #3498db
    background-color: #fff
    transition: background-color 0.3s, color 0.3s
    &:hover
        background-color: #22767d
        color: #fff

.textArea
    width: 100%
    border-radius: 10px
    resize: none
    backgroundColor: #F4F4F4
    border: 1px solid #57555eba
    padding: 5px
    color: #1A1D1F
    overflow: hidden

.desc
    margin-top: 10px