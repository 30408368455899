@import ../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    +t
        position: relative
        display: block
    &:hover
        +rmin(1024)
            background: $n2
            +dark
                background: $n6
            .number,
            .status
                background: $n1
            .number
                +dark
                    background: $n5
            .status
                +dark
                    background: $n7
            .control
                visibility: visible
                opacity: 1
    &:not(:last-child)
        +t
            margin-bottom: 24px
            padding-bottom: 24px
            border-bottom: 1px solid $n3
            +dark
                border-color: $n6

.col
    position: relative
    display: table-cell
    vertical-align: top
    padding: 16px 12px
    color: $n7
    +t
        position: static
        display: flex
        align-items: center
        padding: 0
    +dark
        color: $n1
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 56px
        border-radius: 8px 0 0 8px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:nth-child(4)
        +t
            display: none
    &:not(:first-child):not(:nth-child(2))
        padding-top: 20px
        +t
            padding-top: 0
    &:last-child
        border-radius: 0 8px 8px 0
        &:after
            right: 12px
    &:not(:first-child):not(:last-child)
        +t
            margin-bottom: 12px