@import ../../../styles/helpers

.profile
    display: flex
    align-items: center
    margin-bottom: 32px
    +t
        margin-bottom: 24px
    & > .button
        +m
            display: none

.avatar
    position: relative
    flex-shrink: 0
    width: 96px
    height: 96px
    margin-right: 32px
    +m
        width: 80px
        height: 80px
        margin-right: 16px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.remove
    display: none
    position: absolute
    top: 0
    right: 0
    width: 24px
    height: 24px
    border-radius: 50%
    background: $n1
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25)
    font-size: 0
    +m
        display: inline-block

.file
    position: relative
    display: inline-block
    margin-right: 12px
    overflow: hidden
    +m
        margin-right: 0
    input
        position: absolute
        top: 0
        left: 0
        font-size: 160px
        opacity: 0
    .button
        +m
            padding: 0 10px

.field
    width: 80%
    margin-right : 5px
    &:not(:last-child)
        margin-bottom: 12px
        +t
            margin-bottom: 24px

.customClass
    width: 50%   

.random
    display : flex
    justify-content : space-between
    
.buttonClass
   margin-left : 10px
   
.row
    width : 50%
    display : flex
    margin-left : 10px
    margin-top : 37px 

.customField
    display: flex

.monitorField
    width: 50%

.dropdownClass
    display: flex
    justify-content: space-between
    width: 50%

.field
    &.fieldIcon
        .input
            padding-left: 48px
    &.fieldCurrency
        .input
            padding-left: 58px
            background: none
            border-color: $n3
            &:focus
                border-color: $shades1
            +dark
                border-color: $n6
                &:focus
                    border-color: $n5
    &.fieldCopy
        .input
            padding-right: 48px
    &.error
        .icon
            svg
                fill: $p3
        .wrap
            &:after
                content: ""
                position: absolute
                top: 0
                right: 0
                bottom: 0
                width: 48px
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath d='M12 15a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1zm7.675 2.504L12.87 5.563a1 1 0 0 0-1.738 0L4.327 17.504A1 1 0 0 0 5.196 19h13.61a1 1 0 0 0 .869-1.495zM14.607 4.573c-1.151-2.019-4.062-2.019-5.213 0L2.589 16.514C1.45 18.514 2.894 21 5.196 21h13.61c2.302 0 3.746-2.486 2.606-4.485L14.607 4.573z' fill-rule='evenodd' fill='%23ff6a55'/%3E%3Cpath d='M13 17a1 1 0 1 1-2 0 1 1 0 1 1 2 0z' fill='%23ff6a55'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
        .input
            padding-right: 48px
            background: rgba($s1, .25)
            border-color: transparent
            color: $p3
            +dark
                &:focus
                    border-color: $p3
                    color: $p3
            &::placeholder
                color: $p3
    &.success
        .wrap
            &:after
                content: ""
                position: absolute
                top: 0
                right: 0
                bottom: 0
                width: 48px
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%2383bf6e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
        .input
            padding-right: 48px
            background: $n
            border-color: $shades1
            +dark
                background: $n8

.box
    width: 640px
    height: auto
    margin: 0 auto
    padding: 5px 0
    position: relative

.button
    margin: 26px 0    

.datePickerContainer
    margin-bottom: 20px

.customDatePicker
    width: 100%
    padding: 10px
    font-size: 14px
    color: #333
    border: 1px solid #ccc
    border-radius: 4px
    background-color: #f9f9f9
    box-sizing: border-box
    +dark
        background: $n7
        color: #fff

    &:focus
        outline: none
        border-color: #007bff
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5)

    &::placeholder
        color: #aaa
    
.dateLabel
    margin-bottom: 14px
    +base2
    color: $n5
    +dark
        color: $n3